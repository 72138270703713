/* Google fonts */
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');



::-webkit-scrollbar {
  width: 12px; /* You can set the width as per your preference */
  background-color: var(--white); /* White background color */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

}

/* This styles the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: var(--primary); /* Red thumb color */
  border-radius: 6px; /* Optional: if you want rounded corners */
  border: 3.75px solid white; /* Optional: if you want a slight space between the track and thumb */
}


/* Variables */
:root {
  --color1: #a1633c;
  --color2: #2176ff;
  --color3: #0f915f;
  --color4: #d1391e;
  /* the rest of your variables */
  --secondary: #4869ff;
  --error: #e7195a;
  --good: #10c257;
  --offwhite: #eee9e5;
  --white: #ffffff;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  font-size: 24px; /* Example font size */
}


body {
  background: var(--offwhite);
  margin: 0;
  font-family: "Rubik";
  padding: 20px;
  /* This gives you a 20px gap around the screen */
  box-sizing: border-box;
  /* This ensures padding is included in the total height */
  justify-content: center;
  align-items: center;
}


/* Layout */
.home {
  display: flex;
  flex: 2;
  margin: 0px;
  gap: 20px;
  /* between components*/
  height: calc(100vh - 40px);
}

.side {
  display: grid;
  grid-auto-rows: min-content;
  gap: 5px;
  width: 20%;
  background-color: var(--primary);
  border-radius: 10px;
  height: auto;

}
.side-scrollable {
  height: auto; /* Or any height that fits your layout */
  overflow-y: auto; /* This will enable scrolling */
}

.gallerypad{
  display: grid;
  grid-auto-rows: min-content;
  gap: 5px;
  width: 25%;
  background-color: var(--primary);
  border-radius: 10px;
  height: auto;
  overflow-y: auto; /* This will enable scrolling */
  padding-top: 20px;
  padding-bottom: 20px;
}


.gallery {
  display: flex;
  flex: 1;
  margin: 0px;
  height: calc(100vh - 40px); 
}

.galleryheader{
  font-size: 30px;
  font-weight: 600;
  color: white;
  margin: 20px 20px 10px 20px;

}

.gallerypara{
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin: 0px 20px 0px 20px;

}


.gallery hr{
  color: white;
}

.backbuttongallery{
  color: white;
  cursor: pointer;
  margin: 0px 0px 0px 20px;
  font-size: 10px;
}

.backbuttongallery:hover{
  text-decoration: underline;
}

.centered-header {
  text-align: center;
  margin: 30px;
  margin-bottom: 0px;
  /* This centers block elements */

}

.centered-para {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin: 15px 20px 0px 20px ;
  padding: 8px;
  border: 2px solid var(--offwhite);
  border-radius: 10px;
  transition: background-color 0.1s ease-in-out, color 0.05s ease-in-out, border 0.1s ease-in-out;
  text-decoration: underline;

}

.centered-para:hover{
  background-color: white;
  color: var(--primary);
  border: 2px solid var(--primary);
}




.side-details {
  padding: 8px;
  position: relative;
  cursor: pointer;
  margin-left: 12px;
  margin-right: 12px;
  transition: all 0.2s ease;
  border-radius: 5px;
  width: auto;

}

.side-details:hover {
  background: var(--offwhite);

}

.side-details h4 {
  margin: 0 0 0 0;
  font-size: 14px;
  color: var(--white);

}

.side-details p {
  margin: 0;
  font-size: 12px;
  color: var(--white);
}

.side-details:hover h4 {
  color: var(--primary);
}

.side-details:hover p {
  color: var(--primary);
}

.side-details h4 {
  word-break: break-word;
}



.templates {
  display: flex;
  width: 80%;
  border-radius: 10px;
  height: auto;
  justify-content: center;
}

.template-container {
  display: flex;
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  height: calc(100vh - 80px);

}

.template-full {
  flex: 1;
  margin-right: 20px;
  background: #fff;
  padding: 30px;
  width: 50%;

}


.template-full span {
  top: 30px;
  cursor: pointer;
  color: #333;
  transition: color 0.1s ease-in;
}


/* Additional styling for the template details */
.template-full h1 {
  font-size: 22px;
  font-weight: 600;
}

.template-full h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.template-full h3 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.template-full p {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 20px;
  margin: 0;
  font-size: 10px;
  color: #555;
}

.template-full h1,
.template-full h2,
.template-full h3 {
  word-break: break-word;
}

label,
textarea {
  display: block;
}

textarea {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 2px solid #dddddd;
  border-radius: 8px;
  box-sizing: border-box;
  font-family: "Poppins";
  /* This will prevent manual resizing */
  overflow-y: hidden;
  resize: none; /* Disable manual resizing */

}

/* Styling for textarea fields */
textarea[type="text"] {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  font-family: "Poppins";
}

textarea[type="text"]:focus {
  border-color: var(--primary);
  outline: none;
}


/* form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.1s ease-in;
}


form button:hover {
  background: var(--primary)
} */

div.error {
  padding: 10px;
  background: #ffe5e5;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 8px;
  margin: 20px 0;
}

/* auth forms */


.GoogleButton {
  cursor: pointer;
}

.login {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 40px);
}

.login-image {
  flex: 0.6;
  display: flex;
  justify-content: cover;
  align-items: center;
  position: relative;
  /* This is needed so that the pseudo-element is positioned relative to this container */
}



.login-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;

  /* This will ensure that the image covers the entire container, even if parts are cut off */
}


.login-content {
  flex: 0.4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.login-content h1 {
  font-size: 40px;
}

.login-content h2 {
  padding-bottom: 20px;
  font-size: 20px;
}

.login-content img {
  padding-bottom: 20px;
  height: 45px;

}
/* Initial state of the image */
.hoverable-image {
  transition: transform 0.15s ease;
  cursor: pointer; /* Change the cursor to pointer to indicate it's clickable */
}

/* Hover state of the image */
.hoverable-image:hover {
  transform: scale(1.03); /* This will make the image 5% bigger */
}



@media (max-width: 768px) {

  /* Adjust styles for smaller screens */
  .login {
    flex-direction: column;
  }

  .login-image,
  .login-content {
    flex: 1;
  }
}


.tag.selected {
  background-color: var(--primary);
  color: white;
}


.dropdown {
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}
.item-row {
  display: flex; /* Aligns children inline */
  align-items: center; /* Aligns children vertically */
  justify-content: space-between; /* Puts maximum space between children */
  margin-bottom: 10px; /* Adds some space between item rows */
}

.item-content {
  flex-grow: 1; /* Allows the content to grow and take available space */
}


.LogoImage {
  display: block;
  /* makes the image a block element */
  margin: 0 auto;
  /* centers the image horizontally */
  max-width: 10%;
  /* ensures the image doesn't overflow the form's width */
  height: auto;
  /* maintains the image's aspect ratio */
}

h1,
h2,
h3 {
  margin-bottom: 20px;
}

/* Styling for buttons */

button {
  background-color: var(--primary);
  color: white;
  border: 2px solid white;
  border-radius: 8px;
  padding: 8px;
  font-family: "Poppins";
  cursor: pointer;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

}

button:hover {
  background-color: white;
  color: var(--primary);
  border: 2px solid var(--primary);

}

.emptypage {
  height: 10%;      /* Adjust height as needed */
  width: 30%;       /* Adjust width as needed */
  position: absolute;
  top: 30%;
  left: 45%;
  text-align: center; /* Centers the text horizontally */
}

.login-gohome{
  color: var(--color2);
  padding-bottom: 20px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.backbutton{
  color: black;
  cursor: pointer;
}

.backbutton:hover{
  color:  var(--primary);
}

.concatenated-box a{
  color: var(--color2);
}

.concatenated-box a:hover{
  color: limegreen;
}


/* Styling for links */
a {
  text-decoration: none;
  color: var(--offwhite);
  transition: color 0.3s ease;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: center;

}

a:hover {
  color: #ffffff;
}


/* Styling for material symbols */
.material-symbols-outlined {
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
  transition: color 0.18s ease;
}

.material-symbols-outlined:hover {
  color: var(--error);
}

/* Styling for tags */
.tag {
  font-size: 12px;
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  background-color: var(--offwhite);
  cursor: pointer;
  transition: background-color 1s ease-in-out, color 1s ease-in-out;
}


.fulltempdeets{
  width: 100%;
}

.conversation-container {
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

.chattext {
  width: 58%;
  position: fixed;
  bottom: 10px; /* Adjust as needed for spacing from the bottom */
  left: 30%;
  overflow-y: hidden;
  resize: none;
}

.convosstart{
  width: 60%;
  
  padding-bottom: 20px;
  padding-right: 50px;
  margin-left: 18%;
  margin-bottom: 20px;
  overflow-y: auto;
  max-height: calc(100% - 100px); /* Adjust the height */
}

.user{
  font-weight: 700;
}

/* Styling for markdown content */
.ReactMarkdown {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 0px;
  background-color: #fff;
  margin-bottom: 10px;
}


.concatenated-box {
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  flex: 1;
  border: 1px solid #ccc;
  overflow: auto;
  font-size: 14px;
}


.concatenated-box h4 {
  font-size: 14px;
  padding: 0px;
  margin: 0px;
}

.concatenated-box span {
  top: 20px;
  cursor: pointer;
  color: #333;
  transition: color 0.1s ease-in;

}

.concatenated-box span:hover {
  color: var(--error);
}

table {
  border-collapse: collapse;
  width: auto;
}

table,
th,
td {
  border: 1px solid gainsboro;
}

th,
td {
  padding: 8px 12px;
}


.full-create {
  display: flex;
  justify-content: space-between; /* Add some space between form and preview */
  align-items: flex-start;
  height: calc(100vh - 120px); 
}

.create, .live-template-preview {
  padding: 40px;
  background: #fff;
  flex: 1; /* It takes up the available space */
  overflow-y: auto; /* Enables scrolling */
  border-radius: 20px; /* Gives rounded corners */
  height: calc(100vh - 120px);
}

.live-template-preview {
  margin-left: 20px; /* Space between form and preview */
}


.live-template-preview h4 {
  font-size: 24px;
  font-weight: 600;
} 
.live-template-preview h1 {
  font-size: 20px;
  font-weight: 600;
}

.live-template-preview h2 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.live-template-preview h3 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.live-template-preview p {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 20px;
  margin: 0;
  font-size: 10px;
  color: #555;
}

.live-template-preview h1,
.live-template-preview h2,
.live-template-preview h3 {
  word-break: break-word;
}

.navbar {
  padding: 20px;
  display: flex;
  flex-direction: column; /* Stack children vertically */
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Spacing between user info and buttons */
}

.user-details {
  display: flex;
  flex-direction: column; /* Stack welcome and name vertically */
  margin-left: 10px; /* Space between image and text */
  font-size: 16px;
}




nav {
  color: white;
  font-size: 14px;
  font-weight: 600;
}

nav .user-image {
  height: 40px;
  width: auto; /* to maintain the aspect ratio */
}

.buttons-container {
  display: flex;
  width: 100%; /* Take the max width */
}

nav button {
  flex: 1; /* Buttons take up equal space */
  margin: 0px 3px; /* Space between buttons */
  font-size: 14px;
  font-weight: 600;
}

