.about-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjusted from gap-10 */
}

.about-background {
  position: absolute;
  width: 100%;
  right: 0;
}

.about-section {
  position: relative;
  padding-top: 32px;
}

.about-content {
  display: flex;
  justify-content: space-between; /* This will place children at the start and end */
  align-items: flex-start; /* Align items to the start of the cross axis */
  padding: 100px; /* Adjust the horizontal padding to create space on the sides */
  max-width: 100%; /* Ensure it takes the full width available */
  gap: 200px;
}



.text-container, .image-container {
  flex: 1; /* Each container takes equal width */
}

.image-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex: 1;
  margin-top: 20px;
}
.image-container img {
  max-width: 100%; /* Limits the width to the container's width */
  max-height: 100%; /* Limits the height to the container's height */
  object-fit: contain; /* Ensures the image maintains its aspect ratio */
}


.about-heading-1 {
  font-size: 80px; /* Adjusted from text-3xl */
  font-weight: 700;
  color: #2176ff;
}

.about-heading-2 {
  font-size: 25px; /* Adjusted for xl:text-4xl */
  font-weight: 500;
}

.cards-heading-1 {
  font-size: 60px; /* Adjusted from text-3xl */
  font-weight: 700;
  text-align: center;
  padding-bottom: 30px;
}

.cards-heading-2 {
font-size: 25px; /* Adjusted for xl:text-4xl */
font-weight: 500;
max-width: 1000px;
text-align: center;
margin: auto; /* Add this line to center the heading */
padding-bottom: 50px;
}

.cards-section{
padding-top: 50px;
}

.cards-section p{
max-width: 400px;
word-spacing: 1px;
line-height: 1.2;
}



.about-paragraph {
  margin-top: 8px;
  font-weight: medium;
}

.about-button-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
}

.about-button {
  min-width: max-content;
  color: white;
  background-color: #2176ff; /* Example primary color */
  border-radius: 9999px; /* Tailwind's rounded-full */
  padding: 12px 24px; /* Adjusted from py-3 px-6 */
}

.about-image-container {
  display: flex;
  flex: 1;
}

.aboutsection{
padding-bottom: 50px;
}


:root {
  --division: 3;
  --app-margin: 30px;
    /* Light theme */
    --light-text: #000000;
    /* Dark theme */
    --dark-text: #f5f5f5;
}


.grid-container {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(var(--division), 1fr);
  grid-auto-rows: minmax(300px, auto);
  grid-auto-flow: dense;
  max-width: 1100px;
  margin: 0 auto; /* This will center the grid in the parent container */
}


@keyframes slideFromBottom {
  from {
    /* transform: translateY(50px); */
    opacity: 0;
  }
  to {
    /* transform: translateY(0); */
    opacity: 1;
  }
}

.card {
  padding: 35px;
  border-radius: 20px;
  box-sizing: border-box;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  min-height: 350px;
  opacity: 0; /* Start with 0 opacity so it's initially invisible */
  animation: slideFromBottom 1s ease-in-out forwards;
}

.card:hover {
  transform: translateY(-2px) translateX(2px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}


.card:nth-child(1) {
  animation-delay: 0.4s;
}

.card:nth-child(2) {
  animation-delay: 0.6s;
}

.card:nth-child(3) {
  animation-delay: 0.9s;
}

.card:nth-child(4) {
  animation-delay: 1.2s;
}

.card:nth-child(5) {
  animation-delay: 1.5s;
}

.card:nth-child(6) {
  animation-delay: 1.8s;
}

.card:nth-child(7) {
  animation-delay: 2.1s;
}

.card:nth-child(8) {
  animation-delay: 2.4s;
}


/* ... and so on for each card ... */





.card-1x1 {
  grid-row: span 1;
  grid-column: span 1;
}

.card-1x2 {
  grid-row: span 2;
  grid-column: span 1;
}

.card-2x1 {
  grid-row: span 1;
  grid-column: span 2;
}

.card-2x2 {
  grid-row: span 2;
  grid-column: span 2;
}

.card-1x3 {
  grid-row: span 3;
  grid-column: span 1;
}

.card-3x1 {
  grid-row: span 1;
  grid-column: span 3;
}

.card-3x3 {
  grid-row: span 3;
  grid-column: span 3;
}

.card-2x3 {
  grid-row: span 3;
  grid-column: span 2;
}

.card-3x2 {
  grid-row: span 2;
  grid-column: span 3;
}


.card-2x4 {
  grid-row: span 4;
  grid-column: span 2;
}


@media (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}





.bg-color-lavender {
  background-color: #776cf0;
}

.bg-color-yellow {
  background-color: #ffbe0b;
}

.bg-color-blue {
  background-color: #4e7aff;
}

.bg-color-green {
  background-color: #2ec479;
}

.bg-color-offwhite {
  background-color: #ffffff;
}

.bg-color-red {
  background-color: #ca1b1b;
}

.bg-color-black {
  background-color: #2f2f2f;
}

.bg-color-midnight {
  background-color: #1d0b53;
}


/* ... existing styles ... */
.social-icons {
  margin-top: 0px;
  
}

.social-icons a {
  margin: 20px; /* Adjust as needed */
  color: #000000; /* Or any color you prefer */
  transition: color 0.15s ease-in-out;
  text-decoration: none;
  display: absolute;
  justify-content: center; /* Horizontally center the items */
  align-items: center;    }

.social-icons i {
  font-size: 30px; /* Adjust size as needed */
}

.social-icons a:hover{
  color: #5e5bff;
}


/* ... existing styles ... */

/* ... existing styles ... */



.footer {
  position: relative;
  left: calc(-1 * var(--app-margin));
  width: calc(100% + 2 * var(--app-margin));
  background-color: black; /* Set background to black */
  color: white; /* Set font color to white */
  padding: 10px 0px;
  text-align: center;
  font-size: 16px;
  margin-top: 50px;
}

.card-top-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns content to the top */
  align-items: flex-start; /* Aligns content to the left */
}

.card-bottom-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Aligns content to the bottom */
  align-items: flex-start; /* Aligns content to the left */
}

.card-center-middle {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Aligns content to the center vertically */
  align-items: center;     /* Aligns content to the center horizontally */
}

/* ... your existing styles ... */

.card.light-theme {
  color: var(--light-text);
}

.card.dark-theme {
  color: var(--dark-text);
}


.button-link {
  background-color: rgba(255, 255, 255, 0.5); /* Translucent white background */
  border: none;
  padding: 7px 12px;
  border-radius: 20px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.2s ease;
  color: #000;
  font-weight: 700;
  font-size: 10px;
  text-decoration: none;
}

.button-link:hover {
  background-color: rgba(255, 255, 255, 0.8); /* Slightly less translucent on hover */
}

