.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.header-icon img {
  height: 50px; /* Adjust size as needed */
}

.header-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Adjust the space between links */
}

.header-links a {
  text-decoration: none;
  color: #333; /* Adjust the color to match your design */
  font-weight: bold;
}

.header-links a:hover {
  color: #000; /* Adjust for hover effect */
}


.header-button-text{
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
}